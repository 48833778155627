@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src:
    local(""),
    local("MaterialIcons-Regular"),
    local("MaterialIconsRound-Regular"),
    local("MaterialIconsSharp-Regular"),
    url(/assets/fonts/MaterialIconsRound-Regular.otf) format("otf"),
    url(/assets/fonts/MaterialIconsSharp-Regular.otf) format("otf"),
    url(/assets/fonts/MaterialIcons-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src:
    local("Material Icons Outlined"),
    local("MaterialIconsOutlined-Regular"),
    url(/assets/fonts/MaterialIconsOutlined-Regular.otf) format("trueType");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local("Poppins-Regular"),
    url(/assets/fonts/Poppins-Regular.ttf) format("truetype");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

$dark-background-color: rgb(12 17 29 / 1);
$dark-border-color: rgb(31 36 47 / 1);
$dark-text-color: rgb(203 213 225 / 1);
$primary-color: #14b8a6;
$surface-g: #066e631a;
$surface-g-border: #14b8a666;

@mixin darkTheme {
  background-color: $dark-background-color;
  border-color: $dark-border-color;
  color: $dark-text-color;
  border-width: 1px;
}

@mixin darkThemeWithoutBorder {
  background-color: $dark-background-color;
  color: $dark-text-color;
}

html,
body {
  height: 100%;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.material-icons-outlined {
  font-family: "Material Icons Outlined";
  @extend .material-icons;
}

.material-icons-outlined {
  font-variation-settings:
    "FILL" 0,
    "wght" 400,
    "GRAD" 0,
    "opsz" 24;
}

.p-sidebar-content {
  padding: 0;
}

.p-sidebar-header {
  padding: 0;
  // background-color: #eeeeee !important;
}

.p-sidebar-header {
  justify-content: space-between;
}

.activity .p-inputwrapper {
  width: 100%;
}

span.p-calendar {
  display: block;
  height: 100%;
  // height: auto;

  & > input {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.p-tieredmenu {
  & .p-menuitem-active {
    & > p-tieredmenusub {
      & > .p-submenu-list {
        left: -100%;
      }
    }
  }
}

.profile-pic,
.profile-view-pic {
  display: block;
  img {
    height: 150px !important;
    width: 150px;
    border-radius: 50%;
    position: absolute;
    top: -80px;
    left: 50px;
    padding: 6px;
    background-color: var(--surface-overlay);
  }
}

.community-img {
  img {
    border-radius: 0.375rem;
  }
}

.verified {
  display: block;
  img {
    position: absolute;
    left: calc(150px + 12%);
    top: 40px;
  }
}

.locationUnderDD {
  input {
    width: 100% !important;
  }
}

.envelope {
  svg {
    position: absolute;
    left: 10px;
  }
}

.billing,
.payment,
.integration,
.password {
  & .p-card-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.tiered-menu {
  & .p-overlaypanel-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.p-overlaypanel-content {
  position: relative;
}

.huddle-view,
.connections-tab {
  & .p-card-content,
  & .p-card-body {
    padding-bottom: 0;
  }
}

.responders {
  & .p-tabview-panels {
    padding-left: 0;
  }
}

.guests,
.post-dialog,
.image-quill-dialog,
.map-quill-dialog {
  .p-dialog-content {
    padding: 0;
  }
}

.post-dialog {
  .p-dialog-content {
    overflow: hidden;
  }
}

.view-content {
  & .p-card-content,
  & .p-card-body {
    padding: 0;
  }
}

.gmnoprint,
.gm-fullscreen-control {
  display: none;
}

input[inputmode="decimal"] {
  padding-left: 8px;
}

.mat-drawer-content {
  z-index: initial !important;
}

.vehicles-main .p-carousel {
  & .p-carousel-item {
    max-width: 33.333%;
    padding-left: 6px;
    padding-right: 6px;
  }
}

.dashboard-first-row {
  & .p-carousel-item:last-child {
    flex-basis: calc(33.333333% - 5%) !important;
  }

  & .p-carousel-item {
    margin: 0 1px;
  }

  & .p-carousel-indicators {
    margin-top: 4px;
  }
}

.pac-container {
  z-index: 3000;
}

.p-accordion-tab {
  & .p-toggleable-content {
    border-left: 1px solid #e5e7eb;
    border-bottom: 1px solid #e5e7eb;
    border-right: 1px solid #e5e7eb;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

#gmap-carpool,
#gmap-route,
#gmap-about,
#gmap-route-dialog,
div[id^="quill-map__"],
div[id^="gmap-view"] {
  & div:first-child {
    pointer-events: none;
  }
}

.about,
.dashboard,
.dashboard-first-row,
.trends,
.padding-clear {
  .p-card-body,
  .p-card-content {
    padding: 0;
  }
}

.public-profile-card {
  .p-card-content,
  .p-card-body {
    padding: 0;
  }
}

.discover,
.community {
  & .p-card-content {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.followers,
.followings,
.invitations {
  & .p-card-content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.p-tabmenu-nav-next,
.p-tabmenu-nav-prev {
  background-color: var(--surface-ground);
}

.account {
  background: transparent;
  box-shadow: none;
}

.profile-view {
  & .p-card-content,
  & .p-card-body {
    padding: 0;
  }
}

.timeline {
  .p-card-body {
    padding-bottom: 4px;
  }
}

.connection-followers-row::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.interest-modal {
  & .p-card {
    box-shadow: none;

    & .p-card-body,
    & .p-card-content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.discover-users {
  & .p-dialog-content {
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
  }
}

.discover-communities,
.huddle-create2,
.p-lrb-clear {
  & .p-dialog-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.comment-dialog {
  & .p-dialog-content {
    padding: 0;
  }
}

[contenteditable="true"]:empty:not(:focus):before {
  content: attr(data-ph);
  color: grey;
  font-style: italic;
}

span[data-id="sep"],
span[data-id="comma"] {
  background-color: transparent;
}

span[data-id="comma"] {
  margin-right: 0;
  margin-left: -8px;
  padding: 0;
}

.p-carousel-indicators {
  margin-top: 4px;
}

.p-carousel-indicator {
  &:not(.p-highlight) {
    button {
      background-color: #c7c7c7;
    }
  }

  &.p-highlight {
    background-color: var(--primary-color);
  }
}

.app-drawer {
  height: 100%;
}

.huddle-datepicker {
  table {
    tr > td {
      padding: 0;
    }
  }
}

.carpool-list {
  & .p-accordion-tab {
    margin-bottom: 0;
    & .p-accordion-header-link {
      border: 0;
      border-top-width: 1px;
      border-radius: 0;
    }
  }
}

.thin-scrollbar {
  &:active::-webkit-scrollbar-thumb,
  &:focus::-webkit-scrollbar-thumb,
  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    visibility: hidden;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

app-drawer {
  .mat-drawer-content,
  & .ng-mat-drawer-content {
    @extend .thin-scrollbar;
  }
}

.mat-drawer-inner-container,
.mat-drawer-content,
.ql-container {
  @extend .thin-scrollbar;
}

.mat-drawer-content {
  overflow-x: hidden;
}

.ql-container {
  font-size: 16px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 30%);
}

.horizontal-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.p-datepicker-trigger {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* Apply Primeng Theme */
label.title {
  color: var(--primary-color);
}

.surface-ground {
  background-color: var(--surface-ground) !important;
}

.surface-overlay {
  background-color: var(--surface-overlay) !important;
}

.surface-border {
  border-color: var(--surface-border);
}

.primary-border {
  border-width: 1px;
  border-style: solid;
  border-color: var(--primary-color);
}

.surface-d {
  background-color: var(--surface-d);
}

label.form-title,
span.form-title {
  font-weight: 500;
}

.primary {
  color: var(--primary-color);
}

.highlight-text {
  color: var(--highlight-text-color);
}

.surface-a {
  color: var(--surface-a);
}

.gray-50 {
  color: var(--gray-50);
}

.gray-400 {
  color: var(--gray-400);
}

.gray-900 {
  color: var(--gray-800);
}

.gray-500 {
  color: var(--gray-400);
}

.bg-gray-400 {
  background-color: var(--gray-400);
}

.bg-gray-100 {
  background-color: var(--gray-100);
}

.bg-highlight {
  background-color: var(--highlight-text-color);
}

.unread {
  color: var(--surface-900);
  font-weight: 500;
}

.text-color {
  color: var(--text-color);
}

.surface-400 {
  color: var(--surface-400);
}

.mask-bg {
  background-color: var(--maskbg);
}

.bg-teal-light {
  background-color: #c2ece7 !important;
}

.jumbo {
  font-size: 2.5em;
}

.h-level-1 {
  font-size: 1.875rem;
  font-weight: 700;
}

.h-level-2 {
  font-size: 1.25em;
}

.h-level-3 {
  font-size: 1.15rem;
  font-weight: inherit;
}

.h-level-4 {
  font-size: 1rem;
  font-weight: inherit;
}

.h-level-5 {
  font-size: 0.725rem;
}

.h-level-6 {
  font-size: 0.625rem;
  font-weight: 400;
}

.font-xs {
  font-size: 0.525rem;
  font-weight: 400;
}

.sub-title {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.p-tabmenu {
  & .p-tabmenu-nav {
    background: var(--surface-overlay);
  }
}

.menu-item {
  &:hover {
    background-color: var(--surface-ground);
  }

  &.selected {
    background-color: var(--surface-ground);
  }

  & .selected-text,
  &.selected-text {
    color: var(--primary-color);
  }

  &.selected-border {
    box-shadow: inset 2px 0px 0px var(--primary-color);
  }

  &.menu-item--text {
    font-size: 0.8em;
  }
}

.vertical-border {
  box-shadow: inset 2px 0px 0px var(--primary-color);
}

.mp-button {
  color: var(--primary-color);
  &.mp-button-outlined {
    border-width: 1px;
    border-color: var(--primary-color);
    &:hover {
      background-color: var(--primary-color);
      color: var(--surface-a);
      & .enabled {
        color: var(--surface-a);
      }
    }
    & .enabled {
      color: var(--primary-color);
    }
  }

  &.mp-button-border {
    border-width: 1px;
    color: var(--text-color);
    border-color: var(--surface-border);
  }

  &.mp-button-filled,
  &.mp-button-rounded {
    background-color: var(--primary-color);
    color: var(--surface-a);
  }

  &.mp-button-highlight {
    background-color: var(--highlight-text-color);
  }
}

.primary-bg {
  background-color: var(--primary-color);
  color: var(--surface-a);
}

.mp-menu {
  & ul {
    & > li {
      & a {
        color: var(--primary-color);
        border-color: var(--primary-color);
      }
      &.p-highlight {
        & a {
          border-bottom-width: 4px;
        }
      }
    }
  }
}

.p-checkbox,
.p-radiobutton {
  & div:nth-child(2):not(.p-highlight) {
    border: 2px solid var(--surface-border);

    &:hover {
      border-color: var(--primary-color);
    }
  }
}

.p-chip {
  &.p-chip-selected {
    background-color: var(--primary-color);
    color: var(--surface-a);
  }
}

.mp-selectbutton {
  & > div {
    border-width: 1px;
  }

  & > div:not(:last-child) {
    border-right: 0;
  }

  & > div:not(:first-child) {
    border-left: 0;
  }
}

.mp-border {
  border-color: var(--surface-border);
}

.mp-border-dark {
  border-color: #fff;
}

.mp-border-light {
  border-color: #000;
}

.p-steps {
  & .p-steps-item:before {
    border-top: 1px solid var(--surface-border);
  }
}

.mat-drawer {
  z-index: 1000 !important;
}

.public-chip {
  & .p-chip-text {
    margin: 0;
    font-size: 0.725rem;
  }
}

.view-public {
  color: var(--surface-a);
}

.p-divider-solid {
  &.p-divider-horizontal:before {
    border-top: 1px var(--surface-d);
    border-top-style: solid;
  }
}

.mp-comment-action {
  &:hover > span {
    color: var(--primary-color);
  }
}

.mp-focus {
  &:focus,
  &:focus-within {
    box-shadow: var(--focus-ring);
    border-color: var(--primary);
    outline: 0 none;
    outline-offset: 0;
  }
}

div[role="row"] {
  &.row-active {
    & .more-horiz {
      display: block;
    }
  }
}

.ql-editor {
  &.ql-blank::before {
    color: var(--text-color) !important;
  }
}

.mp-divider {
  &::before {
    border-left: 1px solid var(--surface-d);
  }
}

.mp-card-full {
  & .p-card-content,
  & .p-card-body {
    height: 100%;
  }
}

.mp-cookie {
  background-color: rgba(0, 0, 0, 0.78);
  color: #fff;
}

.public-tiered-menu {
  & .p-overlaypanel-content {
    padding: 0;
  }
}

.discover {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.community {
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.community-discover {
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
}

.offline-flag,
.version {
  .p-message {
    margin: 0;
  }
}

.version {
  & .p-message-icon {
    display: inline-flex;
  }

  & .p-message-detail {
    flex: 1;
  }
}

.mat-drawer-backdrop {
  background-color: var(--maskbg) !important;
  z-index: 1000 !important;
}

.profile-menu {
  left: 160px !important;
  top: 280.65px !important;
}

.p-sidebar-mask {
  z-index: 0 !important;
}

.p-sidebar-right {
  top: 80px !important;
}

.ql-mention-list {
  & > .ql-mention-list-item {
    line-height: 24px;
    padding: 0.5rem;

    &.disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
  }
}

image-cropper {
  & div:first-child {
    img {
      margin: 0 auto;
    }
  }
}

.default-user-cover {
  background-image: url(/assets/images/user-cover-default.svg);
  background-size: contain;
}

.default-huddle-cover-hiking {
  background-image: url(/assets/images/huddle-default-cover-hiking.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.default-huddle-cover {
  background-image: url(/assets/images/huddle-default-cover.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.default-community-cover {
  background-image: url(/assets/images/community-default-cover.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.default-vehicle-cover {
  background-image: url(/assets/images/car.png);
  background-repeat: no-repeat;
  background-position: center;
}

.faq {
  .p-panel-header {
    background-color: transparent;
  }

  .p-panel-toggler {
    background-color: var(--highlight-text-color);
    color: var(--surface-a);
  }
}

spinnericon {
  margin-left: 6px;
}

.surface-lineargradient {
  background-image: linear-gradient(
    to bottom,
    #d2faf5,
    #d7fbf6,
    #ddfdf8,
    #e2fef9,
    #e7fffb
  );
}

.surface-light {
  background-color: #014558;
}

.surface-light-100 {
  background-color: rgba(235, 251, 249, 0.4);
}

.surface-light-200 {
  background-color: rgba(1, 73, 94, 0.4);
}

.explore-carpool {
  background-image: url(./assets/images/carpool-explorer.svg);
}

.inspiration-editor {
  & .ql-editor {
    font-size: 0.925rem;
    background: transparent;
    box-shadow: none;
    border: 0;
    padding-left: 0;
    @extend .thin-scrollbar;

    &.ql-blank::before {
      left: 0;
    }
  }
}

.featured-article {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://www.w3schools.com/howto/photographer.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.file-under {
  & .p-chip {
    border: 1px solid var(--surface-border);
    background-color: transparent;
    & .p-chip-text {
      font-size: 0.925rem;
      margin: 2px;
      color: #fff;
    }
  }

  & > p-chip:not(:last-child) {
    margin-right: 6px;
  }
}

.inspiration-main {
  height: calc(100vh - 80px);
}

.community-carousel-container {
  & .p-carousel-item {
    flex-basis: 40% !important;
    flex-grow: 0 !important;
  }
}

.glow {
  border-radius: 10px;
  box-shadow:
    0 0 2px #fff,
    0 0 2px #fff,
    0 0 5px var(--primary-color),
    0 0 5px var(--primary-color),
    0 0 10px var(--primary-color),
    0 0 20px var(--primary-color);
  -webkit-animation: blink 1s both;
  animation: blink 1s;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  animation-iteration-count: 5;
}

@-webkit-keyframes blink {
  0% {
    box-shadow:
      0 0 2px #fff,
      0 0 2px #fff,
      0 0 5px var(--primary-color),
      0 0 5px var(--primary-color),
      0 0 10px var(--primary-color),
      0 0 20px var(--primary-color);
  }
  100% {
    box-shadow: none;
  }
}

@keyframes blink {
  0% {
    box-shadow:
      0 0 2px #fff,
      0 0 2px #fff,
      0 0 5px var(--primary-color),
      0 0 5px var(--primary-color),
      0 0 10px var(--primary-color),
      0 0 20px var(--primary-color);
  }
  100% {
    box-shadow: none;
  }
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 174px;
  padding: 2px 0;
  background-color: var(--primary-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}

.ribbon-top-left {
  top: 4px;
  left: 5px;
  z-index: 100;
}

.ribbon-top-left span {
  right: 18px;
  top: 34px;
  transform: rotate(-45deg);
}

/* Quill Image Menu */
.quill-image-dropdown__active {
  transform: translate(0, 20px);
  opacity: 1 !important;
  visibility: visible !important;
}

.quill-image-dropdown {
  position: absolute;
  top: 100%;
  left: -100px;
  background: #ffffff;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}
.quill-image-dropdown:before {
  content: "";
  position: absolute;
  top: -6px;
  left: 112px;
  width: 0;
  height: 0;
  box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.05);
  border-top: 6px solid #ffffff;
  border-right: 6px solid #ffffff;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
  transform: rotate(-45deg);
  mix-blend-mode: multiple;
}
.quill-image-dropdown li {
  z-index: 1;
  position: relative;
  background: #ffffff;
  color: #666;
  padding-left: 4px;
}

.quill-image-dropdown li:first-child {
  border-radius: 4px 4px 0 0;
}
.quill-image-dropdown li:last-child {
  border-radius: 0 0 4px 4px;
}
.quill-image-dropdown li:last-child a {
  border-bottom: 0;
}
.quill-image-dropdown a {
  display: block;
  border-bottom: 1px solid var(--surface-border);
  padding: 0.75rem 0.5rem;
  color: inherit;
  font-size: 1rem;
  text-decoration: none !important;
  cursor: pointer;
}

.p-tabview-nav-container {
  & .p-tabview-nav-btn {
    background-color: var(--surface-ground);
    color: var(--primary-color);
    z-index: 1;
  }
}

.public-sidebar-mask {
  width: calc(100% - 20rem);
  background: rgba(0, 0, 0, 0.4);
}

.post-editor {
  & .p-editor-toolbar {
    display: none;
  }

  & .p-editor-content {
    border: none !important;
  }

  & .ql-editor {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    @extend .thin-scrollbar;
  }
}

.ql-tooltip {
  background-color: var(--surface-overlay) !important;
  color: var(--text-color) !important;

  input {
    background-color: var(--surface-ground) !important;
  }
}

.publisher-profile-desc {
  a {
    text-decoration: underline;
    color: #06c;
  }
}

.mp-checkbox-small {
  width: 2rem;
  height: 1rem;

  & .p-inputswitch-slider::before {
    left: 0;
    width: 1rem;
    height: 1rem;
    margin-top: -0.525rem;
  }
}

@media screen and (max-width: 768px) {
  .vehicles-main .p-carousel {
    & .p-carousel-item {
      max-width: 66.666%;
    }
  }
}

@media screen and (max-width: 432px) {
  .vehicles-main .p-carousel {
    & .p-carousel-item {
      max-width: 100%;
    }
  }

  .profile-pic,
  .profile-view-pic {
    & img {
      left: 50%;
      transform: translateX(-50%);
      top: -80px;
    }
  }

  .profile-view-pic,
  .profile-pic {
    & img {
      width: 140px;
      height: 140px !important;
    }
  }

  .verified {
    & img {
      left: 225px;
    }
  }

  .dashboard-first-row {
    & .p-carousel-item:last-child {
      flex-basis: 75% !important;
    }

    & .p-carousel-item {
      margin: 0 1px;
    }

    & .p-carousel-indicators {
      margin-top: 4px;
    }
  }
}

.p-paginator {
  & .p-paginator-pages {
    & > .p-paginator-page {
      &.p-highlight {
        background: var(--highlight-bg);
        color: var(--highlight-text-color);
      }
    }
  }

  & .p-paginator-next,
  & .p-paginator-prev {
    display: none;
  }
}

.bg-blur::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

span[data-keys="address"] {
  font-style: italic;
  font-weight: 600;
}

span[data-keys="users"] {
  font-weight: 600;
}

@media screen and (max-width: 425px) {
  .discover {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

.icon-width > div {
  width: 20px;
  height: 20px;
}
.min-height-vh .mat-drawer-content {
  min-height: 100vh !important;
}

.mp-main {
  // height: calc(100vh - 80px);
  height: 100vh;
}

.layout-sidebar {
  // height: calc(100vh - 10.2rem);
  height: calc(100vh - 5rem);
  &.collapsed {
    transform: translateX(100%);
    right: 0;
  }
}

.surface-teal-300 {
  color: #80cdd5;
}

.mp-loader {
  & .p-dialog-header {
    display: none;
  }

  & .p-dialog-content {
    border-radius: 10px;
  }
}

.ql-mention-list-container {
  background-color: var(--surface-overlay);

  & ul {
    & > li {
      &:hover,
      &.selected {
        background-color: var(--surface-ground);
      }
    }
  }
}

.mention {
  background-color: var(--surface-ground);
}

.ql-mention-list-container {
  min-width: 300px;
}

.radialBarSemi {
  margin-top: -70px;
}

.radial-text {
  top: 60px;
}

.radial-text .text-sm {
  font-size: 0.775rem;
  line-height: 1rem;
}

.pie {
  // margin-left: -48px;
  margin-top: 4%;
}

.pie .apexcharts-legend.apexcharts-align-left.apx-legend-position-left {
  top: 40px !important;
}
//
.donut {
  margin-left: -22px;
  margin-top: 8%;
}
.donut .apexcharts-legend.apexcharts-align-left.apx-legend-position-left {
  margin-top: 15px;
}

.radialBarCircle {
  margin-top: 8%;
  margin-left: -16px;
}
.radialBarCircle
  .apexcharts-legend.apexcharts-align-center.apx-legend-position-left {
  margin-left: 10px;
  margin-top: 18px;
}

.radial-text .no-vice {
  top: -18px;
  left: 110px;
}

.radial-text .pro {
  right: 102px;
  top: -18px;
}

.waitlist,
.quill-image-list {
  & .p-scrollpanel-content {
    padding-bottom: 0;
  }
}

.p-datepicker-month {
  margin-right: 6px;
}

.p-datepicker-header {
  padding-bottom: 0;

  & + .p-datepicker-calendar-container {
    table {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.huddle-datepicker .p-datepicker {
  border: 1px solid var(--surface-d);
  border-radius: 5px;
  margin-top: 10px;
}

.calendar-overlay {
  background: #fff;
}

.automobiles,
.bikes,
.motorcycles {
  & .p-carousel-indicators {
    margin-top: 6px;
    & > .p-carousel-indicator {
      &.p-highlight button {
        background-color: var(--primary-color);
      }
    }
  }
}

.p-chips-multiple-container {
  width: 100%;
  padding: 8px 4px;
}

.testing-mode {
  & .p-tooltip-text {
    width: 250px;
  }
}

.onboard-sticky-shadow {
  box-shadow: 0px -13px 11px -8px #111827;
}

.inspiration-gradient::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0) 22.38%,
    rgba(1, 82, 73, 0.83) 100%
  );
}

.surface-g {
  background-color: $surface-g;
}

.surface-g-border {
  border-color: $surface-g-border;
}

.article {
  background-color: #c1ebe6;
  & span {
    color: #13b2a0;
  }
}

@media (min-width: 375px) {
  .pie {
    // margin-left: -25px;
    & .apexcharts-canvas {
      margin: 0 auto;
      & svg {
        transform: translateY(0px);
      }
    }
  }
  .pie .apexcharts-legend.apexcharts-align-left.apx-legend-position-left {
    top: 30px !important;
  }
  g#SvgjsG2207 {
    transform: translate(0) !important;
  }
  //
  .donut {
    margin-left: 4px;
    margin-top: 4%;

    & .apexcharts-canvas {
      margin: 0 auto;
      & svg {
        transform: translateY(0px);
      }
    }
  }
  //
  .radialBarCircle {
    margin-top: 6%;
    margin-left: 9px;
  }
  //
  .radialBarSemi {
    & .apexcharts-canvas {
      svg {
        transform: translateX(0px);
        position: absolute;
        top: 60px;
      }
      margin: 0 auto;
    }
  }
}

@media (min-width: 425px) {
  .pie {
    margin-left: 0px;
    margin-top: 1%;
  }
  .donut {
    margin-left: 30px;
    margin-top: 5%;
  }
  .radialBarCircle {
    margin-top: 5%;
    margin-left: 34px;
  }
  .radialBarSemi {
    margin-left: -10px;
  }
  .radial-text .no-vice {
    left: 120px;
  }
  .radial-text .pro {
    right: 125px;
  }
}

@media (min-width: 480px) {
  .pie {
    margin-left: 30px;
    margin-top: 5%;
  }
  .donut {
    margin-left: 70px;
    margin-top: 3%;
  }
  .radialBarCircle {
    margin-top: 2%;
    margin-left: 45px;
  }
  .radialBarCircle
    .apexcharts-legend.apexcharts-align-center.apx-legend-position-left {
    margin-left: 25px;
    margin-top: 25px;
  }
  .radialBarSemi {
    margin-left: 120px;
  }
  .radial-text .no-vice {
    left: 148px;
  }
  .radial-text .pro {
    right: 155px;
  }

  .p-chip {
    &:hover:not(.no-hover),
    &.p-chip-selected {
      background-color: var(--primary-color);
      color: var(--surface-a);
    }
  }
}

@media (min-width: 580px) {
  .pie {
    margin-left: 70px;
  }
  .pie .apexcharts-legend.apexcharts-align-left.apx-legend-position-left {
    top: 20px !important;
  }
  .donut {
    margin-left: 120px;
    margin-top: 2.5%;
  }
  .radialBarCircle {
    margin-left: 95px;
  }
  .radialBarSemi {
    margin-left: 170px;
  }
  .radial-text .no-vice {
    left: 195px;
  }
  .radial-text .pro {
    right: 208px;
  }
}

@media (min-width: 600px) {
  .radialBarSemi {
    margin-left: 180px;
  }
}

@media (min-width: 640px) {
  .pie {
    margin-left: -47px;
    margin-top: 8%;
  }
  .donut {
    margin-left: -8px;
    margin-top: 0;
  }
  .radialBarCircle {
    margin-left: -30px;
    margin-top: 3%;
  }
  .radialBarSemi {
    margin-left: 47px;
  }
  .radial-text .pro {
    right: 77px;
  }
  .radial-text .no-vice {
    left: 70px;
  }
  // .mp-main {
  //   height: 100vh;
  // }
  .layout-sidebar {
    height: calc(100vh - 5.1rem);
  }
}

@media (min-width: 768px) {
  .pie {
    margin-left: 4px;
    margin-top: 4%;
  }
  .donut {
    margin-left: 12px;
  }
  .radialBarCircle {
    margin-left: -15px;
  }
  .radialBarSemi {
    margin-left: -10px;
  }
  .radial-text .no-vice {
    left: 102px;
  }
  .radial-text .pro {
    right: 112px;
  }
}

@media (min-width: 882px) {
  .pie {
    margin-left: 60px;
    margin-top: 5%;
  }
  .pie .apexcharts-legend.apexcharts-align-left.apx-legend-position-left {
    top: 15px !important;
  }
  .donut {
    margin-left: 75px;
    margin-top: 8%;
  }
  .donut .apexcharts-legend.apexcharts-align-left.apx-legend-position-left {
    margin-top: 0;
  }
  .radialBarCircle {
    margin-left: 40px;
    margin-top: 7%;
  }
  .radialBarCircle
    .apexcharts-legend.apexcharts-align-center.apx-legend-position-left {
    margin-top: 10px;
  }
  .radialBarSemi {
    margin-left: 115px;
  }
  .radial-text .no-vice {
    left: 140px;
  }
  .radial-text .pro {
    right: 160px;
  }
}

@media (min-width: 992px) {
  .pie,
  .radialBarCircle {
    margin-left: 105px;
  }
  .donut {
    margin-left: 130px;
    margin-top: 7%;
  }
  .radialBarSemi {
    margin-left: 174px;
  }
  .radial-text .no-vice {
    left: 200px;
  }
  .radial-text .pro {
    right: 212px;
  }
}

@media (min-width: 1024px) {
  .pie {
    margin-left: -17px;
    margin-top: 7%;
  }
  .donut {
    margin-left: -14px;
    margin-top: 12%;
  }
  .radialBarCircle {
    margin-left: -30px;
    margin-top: 9%;
  }
  .radialBarSemi {
    margin-left: 10px;
  }
  .radial-text .pro {
    right: 64px;
  }
  .radial-text .no-vice {
    left: 84px;
  }

  .mat-drawer-backdrop {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .pie {
    margin-left: 16px;
  }
  .donut {
    margin-left: 35px;
    margin-top: 10%;
  }
  .radialBarCircle {
    margin-left: 13px;
    margin-top: 7%;
  }
  .radialBarSemi {
    margin-left: 75px;
  }
  .radial-text .no-vice {
    left: 103px;
  }
  .radial-text .pro {
    right: 123px;
  }
}

@media (min-width: 1280px) {
  .pie {
    margin-left: -80px;
    margin-top: 9%;
  }
  .pie .apexcharts-legend.apexcharts-align-left.apx-legend-position-left {
    left: 52px !important;
  }
  .donut {
    margin-left: -52px;
    margin-top: 16%;
  }
  .radialBarCircle {
    margin-left: -75px;
    margin-top: 11%;
  }
  .radialBarSemi {
    margin-left: -10px;
  }
  .radial-text .no-vice {
    left: 20px;
  }
  .radial-text .pro {
    right: 30px;
  }
}

@media (min-width: 1366px) {
  .pie {
    margin-left: -65px;
  }
  .pie .apexcharts-legend.apexcharts-align-left.apx-legend-position-left {
    left: 42px !important;
  }
  .donut {
    margin-left: -40px;
  }
  .radialBarCircle {
    margin-left: -68px;
  }
  .radialBarSemi {
    margin-left: 0px;
  }
  .radial-text .no-vice {
    left: 30px;
  }
  .radial-text .pro {
    right: 45px;
  }
}

@media (min-width: 1400px) {
  .pie {
    margin-left: -54px;
    margin-top: 4%;
  }
  .donut {
    margin-left: -4px;
    margin-top: 4%;
  }
  .pie .apexcharts-legend.apexcharts-align-left.apx-legend-position-left {
    top: 25px !important;
  }
  .radialBarCircle {
    margin-left: -14px;
    margin-top: 0%;
  }
  .radialBarSemi {
    margin-left: -23px;
  }
  .radial-text {
    top: 60px;
  }
}

@media (min-width: 1440px) {
  .pie {
    margin-left: -48px;
    margin-top: 4%;
  }
  .donut {
    margin-left: 0px;
    margin-top: 4%;
  }
  .pie .apexcharts-legend.apexcharts-align-left.apx-legend-position-left {
    top: 25px !important;
  }
  .radialBarCircle {
    margin-left: -14px;
    margin-top: 0%;
  }
  .radialBarSemi {
    margin-left: -16px;
  }
  .radial-text .no-vice {
    left: 42px;
  }
  .radial-text .pro {
    right: 50px;
  }
}

@media (min-width: 1500px) {
  .pie {
    margin-left: -40px;
  }
  .donut {
    margin-left: -18px;
  }
  .radialBarCircle {
    margin-left: -7px;
  }
  .radialBarSemi {
    margin-left: -8px;
  }
  .radial-text .no-vice {
    left: 50px;
  }
  .radial-text .pro {
    right: 57px;
  }
  .radial-text .text-sm {
    font-size: 0.875rem;
  }
}

@media (min-width: 1600px) {
  .radialBarSemi {
    margin-left: 6px;
  }
  .radial-text .no-vice {
    left: 63px;
  }
  .radial-text .pro {
    right: 70px;
  }
}

@media (min-width: 1700px) {
  .pie {
    margin-left: -15px;
  }
  .donut {
    margin-left: 36px;
    margin-top: 3%;
  }
  .radialBarCircle {
    margin-left: 20px;
  }
  .radialBarSemi {
    margin-left: 20px;
  }
  .radial-text .no-vice {
    left: 75px;
  }
  .radial-text .pro {
    right: 80px;
  }
}

@media (min-width: 1800px) {
  .radialBarSemi {
    margin-left: 30px;
  }
  .radial-text .no-vice {
    left: 85px;
  }
  .radial-text .pro {
    right: 93px;
  }
  .chart-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 1920px) {
  .pie {
    margin-left: -42px;
    margin-top: 3%;
  }
  .donut {
    margin-left: 0px;
    margin-top: 3%;
  }
  .radialBarCircle {
    margin-left: 50px;
  }
  .radialBarSemi {
    margin-left: 47px;
  }
  .radial-text .no-vice {
    left: 104px;
  }
  .radial-text .pro {
    right: 105px;
  }
  .chart-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 2000px) {
  .radialBarSemi {
    margin-left: 54px;
  }
}
