/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

p-editor .ql-editor {
  white-space: pre-wrap !important; /* Ensures text wraps */
  word-break: break-word !important; /* Forces long words to break */
  overflow-wrap: break-word !important;
}
